<template>
  <div class="container" style="position: relative">
    <div v-if="!ifLgScreen" style="position: absolute;top: 10px;width: 100%;padding: 0 20px;box-sizing: border-box;display: flex;justify-content: space-between">
      <div @click="$router.push('/')">
        <img style="width: 22px;height: 22px; vertical-align: middle" src="../../assets/img/user/phone.svg" alt="">
        <span style="display: inline-block; vertical-align: middle">{{ $t('login.home_page') }}</span>
      </div>
      <div @click="$router.push('../download')">
        <span style="display: inline-block; vertical-align: middle">{{ $t('footer.download_app') }}</span>
        <img style="width: 22px;height: 22px; vertical-align: middle; transform: scale(-1.0)" src="../../assets/img/user/phone.svg" alt="">
      </div>
    </div>
    <div v-else  style="position: absolute;top:10px;display: flex;justify-content: space-between;padding:0 2%;box-sizing:border-box;width: 100%"><img style="width: 110px; height: 30px;" src="../../assets/img/logo_purple.png" alt=""> <div><img src="" alt=""><span @click="$router.push('/')" style="color: #AB54DB;cursor: pointer">{{ $t('login.back_homepage') }}</span></div></div>
    <div class="login" style="position: relative">
      <div class="title">{{ $t('login.account_login') }}</div>
      <div class="sub-title">{{ $t('login.login_tips') }}</div>
      <div class="tab">
        <!--        <div @click="isActive=true;$refs.loginFormRef.resetFields()" :class="{'bg-change':isActive}">email</div>-->
        <!--        <div @click="isActive=false;$refs.loginFormRef.resetFields()" :class="{'bg-change':!isActive}">mobile phone</div>-->
      </div>
      <div class="form">
        <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef" @keyup.enter.native="handleToLogin">
          <div class="form-group">
            <div v-if="isActive">
              <div class="label">{{ $t('login.account') }}</div>
              <el-form-item prop="email">
                <el-input clearable v-model="loginForm.email" :onkeyup="upsClear()" :placeholder="$t('login.input_phone_email')"></el-input>
              </el-form-item>
            </div>
            <!--            <div v-else>-->
            <!--              <div class="label">Mobile phone</div>-->
            <!--              <el-form-item prop="phone">-->
            <!--                <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>-->
            <!--              </el-form-item>-->
            <!--            </div>-->
          </div>
          <div class="form-group">
            <div class="label">{{ $t('login.password') }}</div>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password" type="password" show-password :placeholder="$t('login.input_pwd')" suffix-icon=""></el-input>
            </el-form-item>
            <!--            <div class="label">authCode</div>-->
            <!--            <el-form-item prop="auth_code"  style="position: relative">-->
            <!--              <el-input v-model="loginForm.auth_code" placeholder="请输入图片上的文字"></el-input>-->
            <!--              <img :src="imgUrl" @click="getImgAuthCode" alt="" style="position: absolute;top: 8px;right: 8px;width: 60px;height: 25px;cursor: pointer">-->
            <!--            </el-form-item>-->
          </div>
        </el-form>
        <div class="btns">
          <el-button class="login-btn" :loading="isLoading" @click="handleToLogin">{{ $t('login.login') }}</el-button>
        </div>
      </div>

      <div class="links" style="position:absolute;right:0px;text-align: right">
        <div class="forget" @click="toForgrt">{{ $t('login.forget_pwd') }}</div>
        <div class="register" @click="toRegister">{{ $t('login.register') }}</div>
      </div>
      <el-checkbox v-model="isSavedPwd" class="links remember"  text-color="#AB54DB" style="width: auto;" @change="saveTip">{{ $t('login.save_pwd') }}</el-checkbox>
    </div>
  </div>
</template>

<script>
import {getAuth, imgAuthCode} from "../../server/auth";
import {checkEmail, checkMobile} from "../../utils/validate";
import {getUserInfo} from "../../server/rebate";
import {screenSize} from "../../utils/tools";
import {Base64} from "js-base64";

export default {
  name: "Login",
  data() {
    return {
      isActive: true,
      isLoading: false,
      imgUrl:'',
      base64code: '',
      isSavedPwd: false,
      loginForm: {
        phone: '',
        auth_code: '',
        email:'',
        password:''
      },
      loginRules:{
        phone:[{ required: true, message: this.$t('login.input_phone'), trigger: 'blur' },
          {validator:checkMobile,trigger: 'blur'}],
        email:[{ required: true, message: this.$t('login.input_email'), trigger: 'blur' },
          // {validator:checkEmail,trigger: 'blur'}
        ],
        password:[{ required: true, message: this.$t('login.input_pwd'), trigger: 'blur' }],
        auth_code:[{ required: true, message: this.$t('login.input_code'), trigger: 'blur' }],
      },
      ifLgScreen:true
    }
  },
  created() {
    // this.$i18n.locale='en'
    // this.getImgAuthCode()
    this.ifLgScreen=screenSize(900)
    // this.$cookies.config([  60*60*1000*24*30,'/'])
    this.$cookies.config(60*60*24*30*3, '')
  },
  mounted() {
    // this.isActive=this.$store.state.isEmailRegister
    if (this.$cookies.get('loginInfo')){
      let loginInfo = this.$cookies.get('loginInfo');
      this.loginForm.email = loginInfo.name
      this.loginForm.password = Base64.decode(loginInfo.pwd)
      this.isSavedPwd = true;
    }
  },
  methods: {
    toForgrt() {
      this.$router.push('/forget_password')
    },
    toRegister() {
      this.$router.push('/register')
    },
    async handleToLogin() {
      // if(window.localStorage.getItem('token')) return this.$message.error('您已登录,请先退出!')
      await this.$refs.loginFormRef.validate( async valid=>{
        if(!valid) return this.$message.error(this.$t('login.sure_login_info'))
        let username='';let acounttype=1
        const {email,phone,password,auth_code}=this.loginForm
        if (this.isActive) username = email
        else{username = phone;acounttype=2}
        this.isLoading = true
        const res = await getAuth(username,password,acounttype,auth_code)
        if (!res || !res.data) {
          this.isLoading = false
        }
        if (res.success === false) return this.$message.error(res.message)
        this.isLoading = false;
        localStorage.setItem('token', res.data.token)
        let loginTime = new Date().getTime().toString()
        localStorage.setItem('loginTime', loginTime);
        const resu = await getUserInfo()
        if (resu && resu.success) {
          const userInfo = resu.data
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
        }
        // this.isActive?localStorage.setItem('isEmail',true):localStorage.setItem('isEmail',false)
        if (this.isSavedPwd){
          let loginInfo = {
            name: username,
            pwd: Base64.encode(password)
          }
          // this.$cookies.set('loginInfo', [JSON.stringify(loginInfo), [new Date().getTime()+60*60*1000*24*30, ['/', ['/', '']]]])
          this.$cookies.set('loginInfo',JSON.stringify(loginInfo))
          // localStorage.setItem('loginInfo', JSON.stringify(loginInfo))
        }
        if (!this.isSavedPwd) this.$cookies.remove('loginInfo')
        this.$message.success(this.$t('login.login_success'))
        await this.$router.push('/center')
      })
    },
    saveTip(){
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf('Safari') > -1){
        if (this.isPrivateMode()) {
          if (this.isSavedPwd == true) {
            this.$message.warning(this.$t('login.open_incognito_mode'));
          }
        }
      }else{
        let fs = window.RequestFileSystem || window.webkitRequestFileSystem;
        if (!fs) {
          console.log("check failed?");
        } else {
          fs(window.TEMPORARY,100,function(){
            console.log('非无痕模式')
          },function(){
            this.$message.warning(this.$t('login.open_incognito_mode'));
          });
        }
      }
    },
    isPrivateMode() {
      var isPrivate = false;
      try {
        window.openDatabase(null, null, null, null);
      } catch (_) {
        isPrivate = true;
      }
      return isPrivate;
    },
    upsClear(){
      this.loginForm.email=this.loginForm.email.replace(/[, ，　]/g,'')
    }

    // async getImgAuthCode(){
    //   const img=await imgAuthCode()
    //   this.imgUrl=window.URL.createObjectURL(img)
    // }
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 23px;
  font-weight: 600;
  color: #181E25;
  margin-bottom: 10px;
}

.sub-title {
  width: 311px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #181E25;
  line-height: 20px;
  margin-bottom: 36px;
}

.tab div {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: #181E25;
  height: 27px;
  line-height: 27px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.tab div:nth-child(1) {
  width: 82px;
}

.tab div:nth-child(2) {
  width: 140px;
}

.bg-change {
  /*background: #F5F5F5;*/
  color: #0933DE !important;
}

/*.active{*/
/*  display: block;*/
/*}*/
/*.not-active{*/
/*  display: none;*/
/*}*/
.form .form-group .label {
  font-size: 14px;
  font-weight: 400;
  color: #181E25;
  margin: 26px 0 14px 0;
}

.form .form-group input {
  width: 325px;
  height: 46px;
  border-radius: 2px;
  border: 1px solid #979797;
}

.btns {
  display: flex;
  width: 100%;
  justify-content: center;
}

.login-btn {
  width: 329px;
  height: 51px;
  background: #AB54DB;
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-top: 45px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
  letter-spacing: 2px;
  line-height: unset;
}

.links {
  width: 200px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #2233de;
  line-height: 20px;
}

/*.links {*/
/*  width: 200px;*/
/*  margin-top: 16px;*/
/*  font-size: 14px;*/
/*  font-weight: 400;*/
/*  color: #2233de;*/
/*  line-height: 20px;*/
/*}*/
.remember{
  color: #606266;
}

.links div {
  cursor: pointer;
  color: #AB54DB;
}

>>>.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #AB54DB;
  border-color: #AB54DB;
}

>>>.el-checkbox__inner{
  background-color: white;
  border-color: #606266;
}
>>>.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #AB54DB;
}

</style>
